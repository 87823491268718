import localforage from "localforage";
import {
    IconEdgeMini,
    IconGridDefault,
    IconGridHorizontal,
    IconGridVertical,
    IconGridWave,
    IconPrintMini,
    IconSizeMini,
    IconUploadMini,
    IconX
} from "@/assets/svg-icons";
import IconCheckGreen from "@/assets/svg-icons/icons/check-green.svg";
import IconArrowWhite from "@/assets/svg-icons/white-arrow.svg";
import IconMaterial from "@/assets/svg-icons/configuration/material.svg";
import IconUpload from "@/assets/svg-icons/icons/card-upload-icon.svg";
import TipCard from "@/components/cards/TipCard";
import ImageEngine from "@/components/imageEngine";
import Preview from "@/components/Preview";
import Alert from "@/components/cards/unique-card/Alert";
import UserImageLibrary from "@/components/UserImageLibrary";
import {MaterialPrices} from "@/data/MaterialPrices";
import {
    ACCEPTED_FILE_SIZE,
    ACCEPTED_FILE_TYPES,
    ACCEPTED_MAXIMUM_FILE_SIZE,
    ACCEPTED_MAXIMUM_PIXEL_SIZE,
    UPLOAD_SERVICE_URL
} from "@/data/UploadInformations";
import {productSizeMixin} from "@/mixins/productSizeMixin";
import {intercomMixin} from "@/mixins/intercomMixin";

import {blankAxios} from "@/utils/setAxios";

import {isArray} from "lodash";

export const configuratorMixins = {
    data: () => ({
        order: {
            selected_size_height: null,
            selected_size_width: null,
            layout: "1 Panel",
            product_material: "",
            product_edge: "",
            product_frame: "",
            product_frame_: null, //! canvas_frame seçildiğinde, Frame seçildiği zaman product_frame : Frame ismi (Wood frame 0.75"), product_frame_ : Frame boyutunu verir (3/4).
            product_border: "",
            product_cropped_image: "",
            product_paper_type: null,
            product_hanging_hardware: null,
            product_image: null,
            total_amount: null,
            quantity: 1,
            size: null,
        },
        // ORDER MODEL
        preSelectedLayout: false,
        selected_material: null,
        selected_frame: null,
        selected_edge: null,
        selected_hardware: null,
        selected_border: null,

        tab: null,
        active_routes: ["metal", "aluminum", "canvas_frame", "hd_acrylic"],

        customSize: false,
        selectedImageResponse: {},

        //! Image Engine Verileri..
        dimensionList: null,
        image: null,
        customImageSize: {
            width: null,
            height: null,
        },
        customWidth: null,
        customHeight: null,
        coords: null,
        previewMode: false,
        previewImage: "",
        prepareSnapShot: false,
        prepareSnapShotForAddToCart: false,
        previewModal: false,
        qualityWarning: false,
        qualityMessage: null,
        imageLibraryUpdated: false,
        imageReady: false,
        layoutChanged: false,

        openLayoutMode: false,
    }),
    methods: {
        customWidthChanged() {
            this.customImageSize.width = this.customWidth.value.pixel;
            this.customImageSize.height = this.customHeight.value.pixel;
            this.order.selected_size_width = this.customWidth.value.inch;
            this.calculatePrice();
        },
        customHeightChanged() {
            this.customImageSize.height = this.customHeight.value.pixel;
            this.customImageSize.width = this.customWidth.value.pixel;
            this.order.selected_size_height = this.customHeight.value.inch;
            this.calculatePrice();
        },
        customSizeSelected(customSize) {
            this.customSize = customSize;
            if (customSize) {
                const selectedSize = this.dimensionList.find((s) => s.selected);
                this.customWidth = this._number_list.find((c) => c.value.inch === selectedSize.width);
                this.customHeight = this._number_list.find((c) => c.value.inch === selectedSize.height);
                this.customImageSize = {
                    width: selectedSize.canvas_size.width,
                    height: selectedSize.canvas_size.height,
                };
                if (selectedSize) {
                    selectedSize.selected = false;
                }
                this.checkQuality();
            }
        },
        checkQuality(currentImageResolution) {
            this.qualityWarning = (currentImageResolution?.width / this.order.selected_size_width) <= 60
            this.$forceUpdate();
        },
        prepareOrUploadImage(image) {
            if (image?.picture_id) this.prepareEnvironment(image);
            else if (!image?.picture_id && image?.file) this.uploadImage(image, true)
        },
        prepareEnvironment(image) {
            this.image = null;
            this.customSize = false;
            this.imageReady = false;
            setTimeout(() => {
                this.dimensionList = this.detectSizes(image?.original?.width, image?.original?.height);
                const selectedSize = this.dimensionList.find((s) => s.selected);
                if (selectedSize) {
                    this.order.selected_size_height = selectedSize.height;
                    this.order.selected_size_width = selectedSize.width;
                }
                this.image = image;
                localforage.setItem("picture_id", image.picture_id)
                this.tab = 1;
                // this.$store.dispatch("SET_LOADER", {status: false});
            }, 300);
            setTimeout(() => {
                this.preSelectedSizeData();
                this.preSelectedPaperData();
                this.preSelectedLayoutData();
            }, 500);
        },
        convertFileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        checkFileType(file) {
            const file_type_check = !ACCEPTED_FILE_TYPES.includes(file["type"])

            if (file_type_check) {
                if (this.$refs?.file) this.$refs["file"].value = null;
                if (this.$refs?.file_input) this.$refs["file_input"].value = null;

                return this.createWarningNotification(this.$t("notify.file_type"));
            }
        },
        checkFileSize(input) {
            const file_size_check = input.files && input.files[0] && input.files[0].size > ACCEPTED_FILE_SIZE;

            if (file_size_check) {
                if (this.$refs?.file) this.$refs["file"].value = null;
                if (this.$refs?.file_input) this.$refs["file_input"].value = null;
                return this.createWarningNotification(this.$t("notify.file_size"));
            }
        },
        checkFilePixelSizes(file) {
            return new Promise((resolve, reject) => {
                const tempImageElement = new Image();
                tempImageElement.src = URL.createObjectURL(file)
                tempImageElement.onload = () => {
                    const width = tempImageElement.width;
                    const height = tempImageElement.height;
                    const uploaded_pixel_size = width * height;
                    if (uploaded_pixel_size > ACCEPTED_MAXIMUM_PIXEL_SIZE) reject(uploaded_pixel_size)
                    else resolve()
                }
            })
        },
        uploadImage(fileObject, fileAlreadyUploaded) {
            this.imageLibraryUpdated = false;
            this.imageReady = false;
            let file = fileObject.file;

            if (!fileAlreadyUploaded) {
                const input = fileObject.target;
                file = fileObject.target.files[0];
                this.checkFileSize(input);
                this.checkFileType(file)

            } else {
                localforage.getItem('uil').then(user_image_library => {
                    user_image_library = user_image_library?.filter(u => u.tempID !== fileObject?.tempID)
                    localforage.setItem('uil', user_image_library)
                })
            }

            this.checkFilePixelSizes(file).then(() => {
                this.processUpload(file);
            }).catch((uploaded_pixel_size) => {
                this.$refs["file"].value = null;
                this.$refs["file_input"].value = null;
                return this.createWarningNotification(this.$t("notify.file_pixel_size", {
                    maximum_pixel_size: ACCEPTED_MAXIMUM_PIXEL_SIZE,
                    uploaded_pixel_size
                }))
            })
        },
        processUpload(file) {
            const formData = new FormData();
            formData.append("file", file);

            this.customSize = false;
            blankAxios
                .post(UPLOAD_SERVICE_URL, formData, {
                    onUploadProgress: progressEvent => {
                        this.$store.commit("SET_PERCENTAGE_LOADER", {
                            show: true,
                            uploading: true,
                            total: progressEvent.total,
                            loaded: progressEvent.loaded,
                            usePercentage: true
                        })
                    }
                })
                .then(async (upload_response) => {
                    const {uploaded_file_info} = upload_response?.data || {}
                    if (upload_response.status === 201) {
                        let image_library = [];
                        // Set User Image Library
                        //! Adding base64 Version to Data
                        uploaded_file_info.original.base64 = await this.convertFileToBase64(file);
                        await localforage.getItem('uil').then((user_image_library) => {
                            const userLibraryObject = {
                                file,
                                ...uploaded_file_info,
                                sizes: this.detectSizes(uploaded_file_info?.original?.width, uploaded_file_info?.original?.height),
                                picture_id: upload_response.data?.picture_id,
                                tempID: new Date().getTime()
                            };

                            if (isArray(user_image_library)) {
                                image_library = user_image_library.filter((image) => image.expire > new Date().getTime());
                                image_library.push(userLibraryObject);
                            } else {
                                image_library.push(userLibraryObject);
                            }
                        });

                        this.image = null;
                        setTimeout(() => {
                            this.dimensionList = this.detectSizes(uploaded_file_info?.original?.width, uploaded_file_info?.original?.height);
                            const selectedSize = this.dimensionList.find((s) => s.selected);
                            if (selectedSize) {
                                this.order.selected_size_height = selectedSize.height;
                                this.order.selected_size_width = selectedSize.width;
                            }

                            this.tab = 1;
                            localforage.setItem('uil', image_library).then(() => {
                                this.image = uploaded_file_info;
                                this.imageLibraryUpdated = true;
                                localforage.setItem("picture_id", upload_response.data?.picture_id)
                                this.$store.commit("SET_PERCENTAGE_LOADER", {
                                    show: true,
                                    uploading: false,
                                })
                                setTimeout(() => {
                                    this.$store.commit("SET_PERCENTAGE_LOADER", {
                                        show: false,
                                        uploading: false,
                                        finishing: false
                                    })
                                }, 200)
                            });
                        }, 300);
                        setTimeout(() => {
                            this.preSelectedSizeData();
                            this.preSelectedPaperData();
                            this.preSelectedLayoutData();
                        }, 305);
                    }
                })
                .catch((e) => {
                    console.log("error", e)
                    this.$store.commit("SET_PERCENTAGE_LOADER", {show: false})
                })
        },
        SET_LAYOUT(val) {
            if (val !== "1 Panel" && !this.multiPanelAvailable) return;
            // this.prepareSnapShot = true;
            // this.layoutChanged = true;
            this.order.layout = val;
        },
        previewImageReady(image) {
            this.addToCart(image);
        },
        imageIsReady() {
            this.imageReady = true;
        },

        callAction(action, payload) {
            this[action](payload);
        },
        //! ====================================================
        SET_MATERIAL_VALUE(material) {
            this.selected_material = material.id;
            this.order.product_material = material.value;
            this.calculatePrice();
        },
        SET_EDGE_VALUE(edge) {
            this.selected_edge = edge.id;
            this.order.product_edge = edge.title;
        },
        SET_FRAME_VALUE(frame) {
            this.selected_frame = frame.id;
            this.order.product_frame = frame.title;
            if (frame.id === 1) this.order.product_frame_ = frame?.value || "";
            else this.order.product_frame_ = frame?.frame || "";
            this.calculatePrice();
        },
        SET_BORDER_DATA(border) {
            this.selected_border = border.id;
            this.order.product_border = border.title;
        },
        SET_HARDWARE_DATA(hardware) {
            this.selected_hardware = hardware.id;
            this.order.product_hanging_hardware = hardware.title;
        },
        SET_MATERIAL_DEFAULTS() {
            const material_name = this.order.product_material;
            if (material_name === "canvas_frame") {
                const noFrame = this.frameData?.find(f => f.id === 1)
                if (noFrame) this.SET_FRAME_VALUE(noFrame)
                const imageEdge = this._canvas_edges?.find(f => f.id === 2)
                if (imageEdge) this.SET_EDGE_VALUE(imageEdge)
            } else if (material_name === "aluminum") {
                const noBorder = this.borderData?.find(f => f.id === 1)
                if (noBorder) this.SET_BORDER_DATA(noBorder)
                const aluminumRails = this.hardwareData?.find(f => f.id === 1)
                if (aluminumRails) this.SET_HARDWARE_DATA(aluminumRails)
            } else if (material_name === "hd_acrylic") {
                const aluminumRails = this.hardwareData?.find(f => f.id === 1)
                if (aluminumRails) this.SET_HARDWARE_DATA(aluminumRails)
            } else {
                const noBorder = this.borderData?.find(f => f.id === 1)
                if (noBorder) this.SET_BORDER_DATA(noBorder)
            }
        },
        nextAction() {
            this.tab++;
        },
        async prepareCart() {
            await new Promise((resolve) => {
                setTimeout(() => {
                    this.tab = 3;
                    resolve()
                }, 300)
            })

            const {product_material, selected_size_height, selected_size_width, layout} = this.order;
            if (!layout && !product_material && !selected_size_height && !selected_size_width) {
                this.createErrorNotification(this.$t("notify.no_material"));
            } else if (!layout) {
                this.createErrorNotification(this.$t("notify.no_layout"));
            } else if (!selected_size_height && !selected_size_height) {
                this.createErrorNotification(this.$t("notify.no_size"));
            } else {
                // this.$store.commit("SET_LOADER", true);
                //! ÖNEMLİ
                //! prepareSnapShotForAddToCart bizim kullanıcının son olarak yapmış olduğu tasarimi Backend'e göndermesi için tetikleniyor.
                //! Yani backend'e bizim kullanıcının son olarak tasarımdaki değişikliğini göndermemiz lazim resim olarak.
                //! bundan dolayi bu field set edilir. imageEngine bunu dinler ve resim oluşturulur.
                //! AddToCart işlemi imageEngine tarafından tetiklenir.
                //! @snapshot-event="addToCart"
                // this.prepareSnapShot = true;
                this.prepareSnapShotForAddToCart = true;
            }
        },
        async addToCart(image) {
            this.openLayoutMode = false;

            const image_file_id = await localforage.getItem("picture_id");
            const customProduct = {
                product_type: this.order.product_material,
                name: null, //TODOGK name ne işe yarıyor?
                file_name: this.image?.original?.name || null,
                hardware: this.order.product_hanging_hardware,
                border: this.order.product_border,
                quantity: this.order.quantity,
                width: this.order.selected_size_width + "", //JSON.stringify yerine Casting.
                height: this.order.selected_size_height + "", //JSON.stringify yerine Casting.
                layout: this.order.layout,
                frame: this.order.product_frame,
                frame_: this.order.product_frame_,
                edge: this.order.product_edge,
                size: this.order.size, //TODOGK size ne işe yarıyor? zaten width ve height var.
                thumbnail_id: null,
                svg_file_base64: this.image.original.base64,
                thumbnail_base64: image,
                image_file_id,
                original_image_url: this.image.original.url,
                crop_coords: this.coords.coords,
                price: this.order.total_amount,
            };

            if (!customProduct.frame) delete customProduct.frame;
            if (!customProduct.edge) delete customProduct.edge;
            if (!customProduct.hardware) delete customProduct.hardware;
            if (!customProduct.border) delete customProduct.border;

            this.$store.dispatch("SET_NEW_ITEM", customProduct).then((_) => {
                setTimeout(() => {
                    // this.$store.commit("SET_LOADER", false);
                }, 1000);
            });

            this.clear();
            this.createSuccessNotification(this.$t("notify.product_added"));


        },
        clear() {
            this.order = {
                layout: null,
                product_material: null,
                product_edge: null,
                product_frame: null,
                product_frame_: null,
                product_cropped_image: null,
                product_paper_type: null,
                product_hanging_hardware: null,
                product_image: null,
                total_amount: null,
                selected_size_height: null,
                selected_size_width: null,
            };
            this.image = null;
            this.qualityWarning = false;
            this.product_image = null;
            this.total_amount = null;
            this.quantity = 1;
            this.tab = 1;
        },
        //! RETURN FALSE var kontrol et..
        checkDimension(dimension) {
            this.customImageSize = {
                width: null,
                height: null,
            };
            this.customWidth = null;
            this.customHeight = null;
            this.order.selected_size_height = dimension.height;
            this.order.selected_size_width = dimension.width;
            if (this.order.product_material) {
                this.calculatePrice();
                this.$forceUpdate();
            }
            // this.checkQuality();
        },
        calculatePrice() {
            if (this.order.product_material !== null) {
                let product_type = this.order.product_material;
                if (this.order.product_material === "canvas_frame" && this.withoutFrame) {
                    product_type = "canvas";
                }
                this.order.total_amount = ((((MaterialPrices[product_type] || []).find((p) => this.imageValueForPrice >= p.min && this.imageValueForPrice < p.max) || {}).price || 0) * this.imageValueForPrice).toFixed(2);
                this.$forceUpdate();
            }
        },

        //! If Size is pre-selected
        preSelectedSizeData() {
            return localforage
                .getItem("custom_sizing")
                .then((custom_size) => {
                    if (custom_size) {
                        this.order.product_material = custom_size?.material || "-";
                        this.selected_material = custom_size.id;

                        const MATCHED_DIMENSION = this.dimensionList?.find((d) => d.width === custom_size?.width && d.height === custom_size?.height);
                        if (MATCHED_DIMENSION) {
                            MATCHED_DIMENSION.selected = true;
                            this.checkDimension(MATCHED_DIMENSION);
                            this.customSize = false;
                            const DIMENSIONS = this.dimensionList.filter((d) => d.width !== MATCHED_DIMENSION?.width && d.height !== MATCHED_DIMENSION?.height);
                            DIMENSIONS.forEach((e) => (e.selected = false));
                            this.calculatePrice();
                            this.tab = 2;
                        } else if (custom_size?.width !== null && custom_size?.height !== null) {
                            this.dimensionList.filter((d) => d.selected).forEach((e) => (e.selected = false));
                            this.customSize = true;
                            // -- //
                            this.customWidth = this._number_list.find((c) => c.value.inch === custom_size?.width);
                            this.customHeight = this._number_list.find((c) => c.value.inch === custom_size?.height);
                            // -- //
                            this.customImageSize.width = this.customWidth.value.pixel;
                            this.customImageSize.height = this.customHeight.value.pixel;
                            // -- //
                            this.order.selected_size_width = custom_size?.width;
                            this.order.selected_size_height = custom_size?.height;
                            this.calculatePrice();
                            this.tab = 2;
                        }
                        this.calculatePrice();
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setTimeout(() => {
                        localforage.removeItem("custom_sizing");
                    }, 100);
                });
        },
        //! If Paper is pre-selected
        preSelectedPaperData() {
            return localforage
                .getItem("paper_data")
                .then((paper) => {
                    if (paper) {
                        this.order.product_material = paper.paper || "-";
                        this.selected_material = paper.id;

                        this.calculatePrice();
                        this.$forceUpdate();

                        this.tab = 2;
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    localforage.removeItem("paper_data");
                });
        },
        //! If Layout is pre-selected
        preSelectedLayoutData() {
            return localforage
                .getItem("layout_data")
                .then((layout) => {
                    if (layout) {
                        this.selected_material = layout.id;
                        this.order.layout = layout?.layout || null;
                        this.preSelectedLayout = true;
                        this.order.product_material = layout?.product_type || "-";
                        //! Bunun yapılmasının sebebi
                        if (layout?.layout !== "1 Panel") {
                            this.customSize = true
                            this.customSizeSelected(true);
                            const maxWidthForMultiPanel = 36;
                            const maxHeightForMultiPanel = 24;

                            const customHeight = this.customHeightList.find(h => h.value?.inch === maxHeightForMultiPanel)
                            const customWidth = this.customWidthList.find(w => w.value?.inch === maxWidthForMultiPanel)
                            this.order.selected_size_height = maxHeightForMultiPanel;
                            this.order.selected_size_width = maxWidthForMultiPanel;
                            this.customHeight = customHeight;
                            this.customWidth = customWidth;
                            this.customImageSize = {
                                width: customWidth?.value?.pixel,
                                height: customHeight?.value?.pixel
                            };
                            this.checkQuality();
                            this.$forceUpdate();
                            this.calculatePrice();
                        }
                        this.$forceUpdate();
                        this.calculatePrice();
                        if (this.image) this.tab = 2;
                    }
                    return localforage.removeItem("layout_data");
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    localforage.removeItem("layout_data");
                });
        },
        //! If Image is pre-selected
        preSelectedImageData() {
            return localforage
                .getItem("product_data")
                .then((product) => {
                    if (product) {
                        this.selected_material = product.id;
                        this.order.product_material = product.product_type;
                        const selected_size = product.image?.sizes?.find((size) => size.selected === true);
                        this.order.selected_size_width = selected_size.width;
                        this.order.selected_size_height = selected_size.height;
                        this.calculatePrice();
                        this.prepareEnvironment(product.image);
                        this.tab = 2;
                    }
                    this.calculatePrice();
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setTimeout(() => {
                        localforage.removeItem("product_data");
                    }, 150);
                });
        },

        //! Sizing Methods
        detectSizes(minWidth, minHeight) {
            const orientation = this.detectOrientation(minWidth, minHeight);
            let sizes = [];
            const horizontal_sizes = [7, 15, 24, 36, 60];
            const vertical_sizes = [5, 10, 16, 24, 35];
            const square_sizes = [5, 10, 16, 24, 35];
            const CANVAS_MAXIMUM_WIDTH = 750;
            const CANVAS_MAXIMUM_HEIGHT = 750;
            const MAX_WIDTH = Math.max(...horizontal_sizes);
            const MAX_HEIGHT = Math.max(...vertical_sizes);

            //! Yatay bir resim seçilmişse
            if (orientation === "horizontal") {
                const orientationRate = minWidth / minHeight;
                sizes = horizontal_sizes.map((size, i) => {
                    const height = Math.round(size / orientationRate);
                    const canvas_width = (size * CANVAS_MAXIMUM_WIDTH) / MAX_WIDTH;
                    const canvas_height = canvas_width / orientationRate;
                    return {
                        width: size,
                        height: height,
                        label: `${size}" x ${height}"`,
                        // selected: size === MAX_WIDTH,
                        selected: i === parseInt(horizontal_sizes?.length / 2),
                        canvas_size: {
                            width: canvas_width,
                            height: canvas_height,
                        },
                        pixel: {
                            width: size * 96,
                            height: height * 96,
                        },
                        suggested_size: true,
                        custom_size: false,
                    };
                });
            } else if (orientation === "vertical") {
                const orientationRate = minHeight / minWidth;
                sizes = vertical_sizes.map((size, i) => {
                    const height = Math.round(size * orientationRate);
                    const canvas_height = (height * CANVAS_MAXIMUM_HEIGHT) / MAX_HEIGHT;
                    const canvas_width = canvas_height / orientationRate;
                    return {
                        height: height,
                        width: size,
                        label: `${size}" x ${height}"`,
                        selected: i === parseInt(vertical_sizes?.length / 2), //size === MAX_HEIGHT
                        canvas_size: {
                            width: canvas_width,
                            height: canvas_height,
                        },
                        pixel: {
                            width: size * 96,
                            height: height * 96,
                        },
                        suggested_size: true,
                        custom_size: false,
                    };
                });
            } else {
                sizes = square_sizes?.filter(s => s > 5)?.map((size, i) => {
                    const canvas_width = (size * CANVAS_MAXIMUM_WIDTH) / MAX_HEIGHT;
                    // const canvas_height = (size * CANVAS_MAXIMUM_HEIGHT) / MAX_HEIGHT;
                    return {
                        width: size,
                        height: size,
                        label: `${size}" x ${size}"`,
                        canvas_size: {
                            width: canvas_width,
                            height: canvas_width,
                        },
                        pixel: {
                            width: size * 96,
                            height: size * 96,
                        },
                        // selected: size === MAX_WIDTH,
                        selected: !this.customSize && i === parseInt(square_sizes?.length / 2),
                        suggested_size: true,
                        custom_size: false,
                    };
                });
            }

            const sizeList = sizes?.filter(s => (s.width >= 5 && s.height >= 5));

            return sizeList?.map((size, i) => ({
                ...size,
                selected: !this.customSize && i === parseInt(sizeList?.length / 2),
            }))
        },
        updateSizes() {
            this.dimensionList = this.detectSizes(this.image?.original?.width, this.image?.original?.height);
            const maxMinSize = Math.min(...[Math.max(...this.horizontalSizes), Math.max(...this.verticalSizes)])
            const invalidSizeCondition = (dimension) => dimension.width > maxMinSize && dimension.height > maxMinSize
            const invalidSizesForSelectedMaterial = this.dimensionList.some(invalidSizeCondition)
            if (invalidSizesForSelectedMaterial) {
                this.dimensionList = this.dimensionList.filter(dimension => !(dimension.width > maxMinSize && dimension.height > maxMinSize));
            }
        },
        detectOrientation(width, height) {
            const _aspectRatio = height / width;
            if (_aspectRatio >= 0 && _aspectRatio < 1) return "horizontal";
            else if (_aspectRatio === 1) return "square";
            else if (_aspectRatio > 1) return "vertical";
        },
    },
    watch: {
        prepareSnapShot(prepareSnapShot) {
            if (prepareSnapShot) {
                setTimeout(() => {
                    this.prepareSnapShot = false;
                }, 300);
            }
        },
        prepareSnapShotForAddToCart(prepareSnapShotForAddToCart) {
            if (prepareSnapShotForAddToCart) {
                setTimeout(() => {
                    this.prepareSnapShotForAddToCart = false;
                }, 300);
            }
        },
        _material_name: {
            handler(val, oldVal) {
                ["product_border", "product_frame", "product_edge", "product_hanging_hardware"].forEach((field) => this.$set(this.order, field, null));

                if (oldVal) {
                    //! Aşağıdaki kod bloğu normalde açıktı. Fakat bir kullanıcı Size belirledikten sonra Material değiştirdiğinde Fiyat Saçmalıyordu. O yüzden bunu kaldırdım.
                    // this.customWidth = null;
                    // this.customHeight = null;
                    // if (!!this.customSize) {
                    //     this.order.selected_size_height = null;
                    //     this.order.selected_size_width = null;
                    //     this.dimensionList.forEach((item) => {
                    //         item.selected = false;
                    //     });
                    //     this.customSize = false;
                    //     //! Set Default Resolution?
                    // }
                }
            },
        },
        //! Bu bizim layout Modumuzu otomatik olarak açacak arkadaş. Layout Her Değiştiğinde bu otomatik olarak açılacak.
        //! Laurence'in isteği doğrultusunda 1 Panel iptal edilmişti gösterimde. Bundan dolayi bu şekilde bir opposite çözüm işimizi görecektir.
        "order.layout"(layout) {
            if (!this.preSelectedLayout) {
                this.openLayoutMode = Boolean(this.image) && layout !== "1 Panel"
            } else {
                this.preSelectedLayout = false;
            }
        },
        "order.product_material"(product_material) {
            //! Ürünün tanımını belirleyen field'lar temizlenmiyor. Bunları temizlediğimiz yer.
            const materialsUsedHangingHardware = ["aluminum", "hd_acrylic"];
            const materialsUsedBorder = ["aluminum", "matte_photo_paper", "satin_photo_paper", "metallic_photo_paper", "textured_fine_art", "hemp_fine_art"];

            //! Product Frame, Edge Kullanmayan bir Material Seçilmişse bu field'lari sıfırla..
            if (product_material !== "canvas_frame") {
                this.order = {
                    ...this.order,
                    product_frame: null,
                    product_frame_: null,
                    product_edge: null,
                };
            }

            //! Hanging Hardware Kullanmayan bir Material Seçilmişse Hanging Field'ini sıfırla..
            if (!materialsUsedHangingHardware.includes(product_material)) {
                this.order = {
                    ...this.order,
                    product_hanging_hardware: null,
                };
            }

            //! Border Kullanmayan bir Material Seçilmişse Border Field'ini sıfırla..
            if (!materialsUsedBorder.includes(product_material)) {
                this.order = {
                    ...this.order,
                    product_border: null,
                };
            }

            this.updateSizes();
            this.calculatePrice();
        },
        multiPanelAvailable(isAvailable) {
            if (!isAvailable) {
                this.SET_LAYOUT('1 Panel')
            }
        },
        tab(activeTab) {
            if (activeTab === 3) this.SET_MATERIAL_DEFAULTS();
            if (this.openLayoutMode && activeTab !== 2) {
                this.openLayoutMode = false;
            }
        },
        "order.selected_size_width"(){
            this.calculatePrice()
        },
        "order.selected_size_height"(){
            this.calculatePrice()
        },
    },
    computed: {
        nextBtnStatus() {
            if (this.tab === 1 && this.image && this.selected_material) return true
            else if (this.tab === 2 && this.image && this.selected_material && this.order.selected_size_height) return true
            else if (this.tab === 3 && this.image && this.selected_material && this.order.selected_size_height && this.requiredAddonsSelected) return true
            else return false;
        },
        selected_product_width() {
            return this.getProp(this.customWidth, "value.inch", null);
        },
        selected_product_height() {
            return this.getProp(this.customHeight, "value.inch", null);
        },
        showAddToCartBtn() {
            return this.order.total_amount && this.order.selected_size_height && this.image && this.order.product_material && this.order.layout && this.requiredAddonsSelected;
        },
        selectedMaterial() {
            return this.materialData.find((item) => item.value === this.order.product_material);
        },
        _material_name() {
            return this.$store.getters[`get_material_${localStorage.lang}`]?.find((e) => e.value === this.order.product_material)?.title || null;
        },
        _canvas_edges() {
            return this.$store.getters[`get_edge_${localStorage.lang}`]?.filter((e) => e.value !== "Metal Border" || "");
        },
        _is_edge_active() {
            return (this.selectedMaterial?.addons || []).length > 0;
        },
        _is_layout_active() {
            return !(this.image && this.order.product_material);
        },
        withoutFrame() {
            return ["Canvas roll only", "EN ROULEAU"].includes(this.order?.product_frame);
        },
        addons() {
            return [
                {
                    id: "frame",
                    title: this.$t("configurator.border.frame"),
                    items: this.frameData,
                    value: "product_frame",
                    action: "SET_FRAME_VALUE",
                },
                {
                    id: "edge",
                    title: this.$t("configurator.border.edge"),
                    items: this._canvas_edges,
                    value: "product_edge",
                    action: "SET_EDGE_VALUE",
                },
                {
                    id: "border",
                    title: this.$t("configurator.border.border"),
                    items: this.borderData,
                    value: "product_border",
                    action: "SET_BORDER_DATA",
                },
                {
                    id: "hangingHardware",
                    title: this.$t("configurator.border.hang"),
                    items: this.hardwareData,
                    value: "product_hanging_hardware",
                    action: "SET_HARDWARE_DATA",
                },
            ];
        },
        edgeFrameIsNotSelected() {
            return ["product_frame", "product_edge", "product_border", "product_hanging_hardware"].map((prop) => !!this.order[prop]).filter((item) => !!item).length === 0;
        },
        hangingHardwareAvailable() {
            return ["metal", "hd_acrylic"].includes(this.order.product_material);
        },
        borderAvailable() {
            return ["metal"].includes(this.order.product_material);
        },
        imageSize() {
            const matchSize = this.dimensionList.find((s) => s.selected);
            return {
                width: matchSize?.canvas_size?.width || this.customImageSize?.width,
                height: matchSize?.canvas_size?.height || this.customImageSize?.height,
            };
        },
        imageValueForPrice() {
            return (this.order.selected_size_height * this.order.selected_size_width) / 144;
        },
        materialData() {
            return this.$store.getters[`get_material_${localStorage.lang}`] || [];
        },
        hardwareData() {
            return this.$store.getters[`get_hardware_${localStorage.lang}`] || [];
        },
        frameData() {
            return this.$store.getters[`get_frame_${localStorage.lang}`] || [];
        },
        borderData() {
            return (this.$store.getters[`get_border_${localStorage.lang}`] || []).filter((item) => this.selectedMaterial?.borderTypes?.includes(item.id));
        },
        roundedList() {
            return Array.from({length: 96}).map((item, index) => {
                const width = ((index + 5) * 750) / 69;
                const height = ((index + 5) * 750) / 69;
                return {
                    value: {
                        pixel: (index + 5) * 20,
                        inch: index + 5,
                    },
                    text: `${index + 5}"`,
                    width,
                    height,
                };
            });
        },
        decimalList() {
            return Array.from({length: 96}).map((item, index) => {
                const width = ((index + 5.5) * 750) / 69;
                const height = ((index + 5.5) * 750) / 69;
                return {
                    value: {
                        pixel: (index + 5.5) * 20,
                        inch: index + 5.5,
                    },
                    text: `${index + 5.5}"`,
                    width,
                    height,
                };
            });
        },
        _number_list() {
            const numbers = [...this.decimalList, ...this.roundedList];
            return numbers.sort((a, b) => parseInt(b.text) - parseInt(a.text)).reverse();
        },
        horizontalSizes() {
            return this.getProp(this.selectedMaterial, "horizontalSizes", []);
        },
        verticalSizes() {
            return this.getProp(this.selectedMaterial, "verticalSizes", []);
        },
        requiredAddons() {
            return this.getProp(this.selectedMaterial, "addons", []).filter((item) => !["installationKit"].includes(item));
        },
        requiredAddonsSelected() {
            return (
                this.addons
                    .filter((addon) => this.requiredAddons.includes(addon.id))
                    .map((addon) => !!this.order[addon.value])
                    .filter((value) => value).length === this.requiredAddons.length
            );
        },
        selected_size_height() {
            return this.getProp(this.customHeight, "value.inch", null);
        },
        selected_size_width() {
            return this.getProp(this.customWidth, "value.inch", null);
        },
        selectedProductArea() {
            return this.order.selected_size_height * this.order.selected_size_width;
        },
        minMultiPanelLayoutSelection() {
            return 24 * 36;
        },
        multiPanelAvailable() {
            const {selected_size_height: height, selected_size_width: width} = this.order || {};
            const isHeightAvailable = width >= 36 && height >= 24
            const isWidthAvailable = height >= 36 && width >= 24
            return isWidthAvailable || isHeightAvailable
            // return this.selectedProductArea >= this.minMultiPanelLayoutSelection;
        },
        selectedDimension() {
            return {
                total: this.dimensionList?.length,
                index: this.dimensionList?.findIndex(s => s.selected)
            }
        }
    },

    created() {
        this.preSelectedImageData();
    },
    metaInfo() {
        const configuratorMetaInfo = {
            title: this.$t("titles.print"),
            titleTemplate: "%s - Blankspace",
            htmlAttrs: {
                lang: "en",
            },
            meta: [
                {charset: "utf-8"},
                {name: "description", content: this.$t("titles.print-content")},
                {
                    name: "viewport",
                    content: "width=device-width, initial-scale=1",
                },
            ],
        };
        return this.$route.name === "Configurator" ? configuratorMetaInfo : {}
    },
    mixins: [productSizeMixin, intercomMixin],
    components: {
        IconEdgeMini,
        IconGridDefault,
        IconGridHorizontal,
        IconGridVertical,
        IconGridWave,
        IconPrintMini,
        IconSizeMini,
        IconUploadMini,
        IconX,
        IconArrowWhite,
        IconCheckGreen,
        IconMaterial,
        IconUpload,
        TipCard,
        ImageEngine,
        UserImageLibrary,
        Preview,
        Alert,
    },
};
